import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"
import ContactModule from "../components/ContactModule/ContactModule"
import ProposalModule from "../components/ProposalModule/ProposalModule"
import { graphql } from "gatsby"

const Index = ({ data }) => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Beegital"
          subTitle=""
        />
        <BasicTextModule
          title="Let's Beegital"
          content="Automatizamos tus procesos y te acompañamos para que des el salto a la tecnología del futuro."
          link="/about"
          linkText="Más"
        />
        <PerksModule content="Cuéntanos tus desafíos y preferencias para elegir la mejor tecnología">
          <Perk title="" content="" />
        </PerksModule>
        <Features
          title="Te acompañamos durante todo el proceso"
          introduction=""
        />
        <ProposalModule
          photos={data.proposalPhotos.edges}
        />
        <ContactModule
          title="Trabajemos juntos"
          introduction="Automatizamos tus procesos para que tus colaboradores puedan trabajar en lo que realmente importa."
        />
      </Layout>
    </>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    proposalPhotos: allFile(
      filter: {ext: {regex: "/(png)/"}, relativeDirectory: {eq: "proposal"}}
      sort: {fields: base, order: ASC}
    ) {
      edges {
        node {
          id
          base
          childImageSharp {
            gatsbyImageData(width: 100, transformOptions: {cropFocus: CENTER})
          }
        }
      }
    }
  }
`