import * as React from "react"
import { ProposalStyles } from "./ProposalModuleStyles"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AnimSize from "../AnimMain/AnimSize"

const Proposal = ({ image, proposal }) => {

  return (
    <AnimSize>
      <ProposalStyles>
        <div className="proposal__item--content">
          <div className="column" id={image.node.id}>
            {proposal.content}
          </div>
        </div>

        <div className="proposal__head">
          <h4>{proposal.title}</h4>
          <div key={image.node.id} className="proposal__item--img">
            <GatsbyImage image={getImage(image.node)} alt={image.node.data} />
          </div>
        </div>
      </ProposalStyles>
    </AnimSize>
  )
}

export default Proposal
