import styled from "styled-components"

export const ProposalModuleStyles = styled.section`
  height: 75vh;

  > div {
    &.container__scroll {
      min-height: 75vh;
      gap: calc(var(--gap) / 2);
      padding: var(--gap) var(--borderSpacing);
      margin-left: calc(var(--borderSpacing) * -1);
      width: calc(100% + (var(--borderSpacing) * 2));
      justify-content: center;
      border: 1px solid var(--primary);
      border-radius: 50px;
      align-items: center;

      @media (min-width: 414px) {
        flex-wrap: wrap;
      }
      @media (min-width: 1200px) {
        padding: 20 0;
        width: 100%;
        margin-left: auto;
        gap: calc(var(--gap) * 4);
      }
    }
    &.intro {
      max-width: 700px;

      h2 {
        display: inline-block;
        border-bottom: 2px solid rgba(255,255,255,0.15);
      }
    }
  }
`

export const ProposalStyles = styled.aside`
  background-color: var(--primary);
  overflow: hidden;
  scroll-snap-align: center;
  scroll-margin-left: 25px;
  position: relative;
  border-radius: 100px;
  border: 2px solid rgba(255, 255, 255, 0.15);
  transition: border-color 0.6s ease, box-shadow 0.6s ease;
  text-align: center;
  justify-content: center;
  color: black;

  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;

  @media (max-width: 414px) {
    flex: 0 0 80%;
  }

  @media (min-width: 414px) {
    min-height: 134px;
    flex-basis: 17%;
  }

  @media (min-width: 768px) {
    flex-basis: 46.3%;
    min-height: 150px;
  }

  @media (min-width: 1024px) {
    flex-basis: 22%;
    min-height: 200px;
  }
  
  @media (min-width: 1415px) {
    flex-basis: 22.4%;
    min-height: 200px;
  }

  @media (min-width: 1530px) {
    flex-basis: 22.8%;
    flex-shrink: 1;
  }

  .proposal__item--img {
    padding: 20px 0 0 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    display: block;
  }

  h4 {
    margin: 40px 0 0 0;
  }

  .proposal__item--content {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    padding: 20 0 0 0;
    top: 30%;
    left: 0;
    right: 0;
    display: none;
    text-wrap: balance;

    .column {
      align-items: center;
      text-align: center;
    }

    @media (min-width: 768px) {
      padding: 20px;
    }

    @media (min-width: 1024px) {
      padding: 40px 20px;
    }

    @media (min-width: 1200px) {
      padding: 30px 30px;
    }

    p {
      color: var(--bodyColor);
    }
  }

  .proposal__head {
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    border-color: var(--primary);
    box-shadow: var(--boxShadow);

    .proposal__item--img {
      transform: scale(2.3);
      opacity: 0.1;
    }

    .proposal__item--content {
      top: 0;
      wrap: evenly;
      display: flex;
      position: absolute;
      z-index: 2;
      top: 10;
      left: 0;
      right: 0;
      padding-top: 70px;
    }

    .column {
      display: flex;
    }

    h4 {
      padding-bottom: 60px
    }
  }
`