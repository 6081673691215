import * as React from "react"
import Button from "../Button/Button"

const ContactForm = () => {
  return (
    <form name="contact" action="https://getform.io/f/0445a93f-1722-4710-86b7-af6b94d6f028" method="POST">
      <input placeholder="Ingresa tu nombre..." type="text" name="name" />
      <input placeholder="Ingresa tu email..." type="email" name="email" />
      <input placeholder="El nombre de tu empresa..." type="text" name="company" />
      <textarea
        placeholder="Tu mensaje..."
        name="message"
        rows="5"
      ></textarea>
      <Button text="Enviar mensaje" type="submit" />
    </form>
  )
}

export default ContactForm