import React, { useState } from "react"
import { motion } from "framer-motion";

const AnimSize = ({ children }) => {
  const [isBig, setIsBig] = useState(false);

  return (
    <motion.div
      className="animDiv"
      animate={{
        height: isBig ? 500 : 215,
        width: isBig ? 300 : 215,
      }}
      onHoverStart={() => setIsBig((isBig) => !isBig)}
      onHoverEnd={() => setIsBig((isBig) => !isBig)}
    >
      {children}
    </motion.div>

  );
};

export default AnimSize
