import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { ContactModuleStyles } from "./ContactModuleStyles"
import ContactForm from "./ContactForm"

const ContactModule = ({ title, introduction }) => {
  return (
    <ContactModuleStyles className="section" id="contactUs">
      <StaticImage
        className="contact__image--bg"
        src="../../../static/panel.jpg"
        alt="Contact Module"
        layout="constrained"
      />
      <div className="container container__tight start">
        {title || introduction ? (
          <div className="intro__area">
            {title &&
              <h2>
                {title}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h2>}
            {introduction && <p>{introduction}</p>}
          </div>
        ) : null}
      </div>

      <div className="container container__tight center contact">
        <ContactForm />
      </div>
    </ContactModuleStyles>
  )
}

export default ContactModule