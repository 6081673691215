import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import Perk from "./Perk"

const PerksModule = ({ content }) => {
  return (
    <PerksModuleStyles className="section section__padding">
      <StaticImage
        className="perks__image--bg"
        src="../../../static/abstract-building.jpg"
        alt="Perks Module"
        layout="constrained"
      />
      <div className="perks__image--overlay"></div>
      <div className="container container__tight">
        <Perk
          title="Automation Anywhere"
          content="Tecnología lider en la industria. Orientada a grandes empresas compromentidas con la automatización."
        >
          <StaticImage
            src="../../../static/logos/automation-anywhere.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Perk>
        <span className="perks__divider">
          <Cross />
        </span>
        <Perk
          title="Power Automate"
          content="Tecnología ofrecida por Microsoft con explosivo exito. Orientada a acercar la automatización a todo tipo de empresas."
        >
          <StaticImage
            src="../../../static/logos/power-automate.svg"
            alt="Perk Image"
            layout="constrained"
            placeholder="tracedSVG"
          />
        </Perk>
      </div>
      <div className="container container__tight">
        <div>
          {content && (
            <h5 style={{ marginTop: "50px", marginBottom: "0" }}>
              {content}
              <span style={{ color: "var(--primary)" }}>.</span>
            </h5>
          )}
        </div>
      </div>
    </PerksModuleStyles>
  )
}

export default PerksModule
