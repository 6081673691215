import * as React from "react"
import { ProposalModuleStyles } from "./ProposalModuleStyles"
import Proposal from "./Proposal"

const ProposalModule = ({ photos }) => {

  const proposals = [
    {
      "title": "Excelencia",
      "content": "Utilizando tecnología RPA, o Robotic Process Automation, desarrollamos soluciones de excelencia para la automatización de procesos repetitivos y de alto impacto. Nuestras soluciones te permitirán avanzar en la Transformación Digital, dejando atrás a la competencia.",
      "imageName": "quality"
    },
    {
      "title": "Eficiencia",
      "content": "Nuestro equipo de expertos trabaja en base a nuestra metodología Beegital, automatizando tus procesos de manera ágil y flexible. Nos ajustámos a tus necesidades, y nos enfocamos en lograr una comprensión profunda de tus procesos, lo que nos permite desarrollar soluciones RPA especializadas para maximizar el valor de tu negocio.",
      "imageName": "efficiency"
    },
    {
      "title": "Flexibilidad",
      "content": "No importa cómo trabajes, —ya sea con Excel, SAP, PDFs, correos, o cualquier otro programa que utilices— nuestras soluciones pueden integrarse con prácticamente cualquier herramienta, unificando tus procesos en flujos automatizados únicos.",
      "imageName": "flexibility"
    },
  ]

  return (
    <ProposalModuleStyles className="section">
      <div className="container container__tight">
        <div>
          <h2 className="intro">
            Nuestra Propuesta
            <span style={{ color: "var(--primary" }}>.</span>
          </h2>
        </div>
      </div>

      <div className="container container__tight container__scroll">
        {photos.map(image => {
          return (
            <Proposal
              image={image}
              proposal={proposals.find(proposal => proposal.imageName === image.node.base.split('.')[0])}
            />
          )
        })}
      </div>

    </ProposalModuleStyles>
  )
}

export default ProposalModule;
