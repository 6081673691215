import * as React from "react"
import { Link } from "gatsby"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button/Button"

const BannerModule = ({ children, title, subTitle, enquire }) => {
  return (
    <>
      <BannerModuleStyles>
        {children ? (
          children
        ) : (
          <StaticImage
            className="banner__image"
            imgClassName="banner__image--content"
            src="../../../static/macbook-color.jpg"
            alt="Banner Image"
            layout="fullWidth"
            placeholder="blurred"
          />
        )}

        <div className="container">
          <div className="banner__content">
            {title && (
              <h1>
                {title}
                <span style={{ color: "var(--primary)" }}>.</span>
              </h1>
            )}
            <div>
              {subTitle && (
                <h2>{subTitle}</h2>
              )}
            </div>
            <div className="banner__btns">
              {enquire && (
                <Button
                  className="btn"
                  text="Contáctanos"
                  as={Link}
                  to="/contact"
                />
              )}
              <Button
                text="Contáctanos"
                as={Link}
                to="/#contactUs"
              />
            </div>
          </div>
        </div>
        <div className="gradient"></div>
      </BannerModuleStyles>
    </>
  )
}

export default BannerModule
