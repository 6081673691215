import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FeaturedProductStyles } from "./FeaturesStyles"
import RichText from "../RichText"

const FeaturedProduct = ({ feature, index }) => {
  const { headerImage, title, description } = feature
  const image = getImage(headerImage)

  return (
    <FeaturedProductStyles>
      <GatsbyImage
        className="features__item--img"
        image={image}
        alt="Step Image"
      />
      {title && description && (
        <div className="features__item--content">
          {title && <h4>{title}</h4>}
          {description && (
            <div className="column" id={index}>
              <RichText richText={description} />
            </div>
          )}
        </div>
      )}
    </FeaturedProductStyles>
  )
}

export default FeaturedProduct
